import React from "react";
import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import { Link, matchPath, useLocation } from "react-router-dom";
import { badgeColors, colors } from "@pm-frontend/styles";
import URL from "@pm-shared/utils/url";
import { PmFilledButton } from "@pm-frontend/shared/components/Buttons/PmFilledButton";
import { LinkHelper } from "@pm-shared/utils/link";
import { PmBadge } from "@pm-frontend/shared/components/PmBadge";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { stringify } from "@pm-assets/js/common/utils/location-utils";
const getIsPathMatch = (pathname, globRoutes, ignoreRoutes, highlightExact) => {
  let isPathMatch = globRoutes.some((route) => {
    return matchPath(pathname, {
      path: LinkHelper.normalize(route),
      exact: !!highlightExact
    });
  });
  if (isPathMatch && ignoreRoutes) {
    return !ignoreRoutes.some((route) => {
      return matchPath(pathname, {
        path: LinkHelper.normalize(route),
        exact: false
      });
    });
  }
  return isPathMatch;
};
const getUrl = (linkProps) => {
  const params = linkProps.queryParams ? stringify(linkProps.queryParams) : "";
  return params ? linkProps.to + "?" + params : linkProps.to;
};
const getRenderMenuChild = (pathname, isMobile, toggleOpen) => {
  return (child) => {
    if (!child.enabled) {
      return null;
    }
    const isHighlighted = getIsPathMatch(
      pathname,
      child.highlightRoutes,
      child.hightlightIgnoreRoutes,
      child.highlightRoutesExact
    );
    return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: child.text }, /* @__PURE__ */ React.createElement(
      Link,
      {
        to: LinkHelper.normalize(getUrl(child.linkProps)),
        onClick: isMobile ? toggleOpen : void 0,
        "data-testid": child.dataTestId
      },
      /* @__PURE__ */ React.createElement(
        EuiText,
        {
          color: isHighlighted ? colors.brand.meldBlue : colors.neutrals.gray800,
          size: "s",
          style: { fontWeight: 600 }
        },
        child.text
      )
    ));
  };
};
const renderMenuChildren = (pathname, menuOptionChildren, isMobile, toggleOpen) => {
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "s", style: { paddingLeft: "24px" } }, menuOptionChildren.map(getRenderMenuChild(pathname, isMobile, toggleOpen)));
};
const getRenderCollapsedMenuItem = (pathname) => {
  return (menuOption) => {
    if (!menuOption.parentItem.enabled) {
      return null;
    }
    const highlightParentRoute = getIsPathMatch(
      pathname,
      menuOption.parentItem.highlightRoutes,
      menuOption.parentItem.hightlightIgnoreRoutes,
      menuOption.parentItem.highlightRoutesExact
    );
    return /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { height: "24px", justifyContent: "center" }, key: menuOption.parentItem.text }, /* @__PURE__ */ React.createElement(Link, { to: LinkHelper.normalize(getUrl(menuOption.parentItem.linkProps)) }, menuOption.parentItem.icon(highlightParentRoute)));
  };
};
const renderCollapsedMenuItems = (pathname, menuOptions) => {
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", alignItems: "center", justifyContent: "center", style: { gap: "12px" } }, menuOptions.map(getRenderCollapsedMenuItem(pathname)));
};
const getRenderMenuOption = (pathname, isMobile, toggleOpen) => {
  return (menuOption) => {
    var _a;
    if (!menuOption.parentItem.enabled) {
      return null;
    }
    const highlightParentRoute = getIsPathMatch(
      pathname,
      menuOption.parentItem.highlightRoutes,
      menuOption.parentItem.hightlightIgnoreRoutes,
      menuOption.parentItem.highlightRoutesExact
    );
    const parsedParentItem = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "xs" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, justifyContent: "spaceBetween" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        direction: "row",
        responsive: false,
        gutterSize: "s",
        alignItems: "center",
        style: { height: "24px" }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, menuOption.parentItem.icon(highlightParentRoute)),
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, /* @__PURE__ */ React.createElement(
        EuiText,
        {
          size: "s",
          color: highlightParentRoute ? colors.brand.meldBlue : colors.neutrals.gray800,
          style: { fontWeight: 600 }
        },
        menuOption.parentItem.text
      ))
    )), menuOption.parentItem.badgeText && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      PmBadge,
      {
        bgColor: badgeColors.light.salmon.bg,
        textColor: badgeColors.light.salmon.text,
        text: menuOption.parentItem.badgeText
      }
    )))));
    let showChildren = highlightParentRoute;
    if (isMobile) {
      showChildren = (((_a = menuOption.childrenItems) == null ? void 0 : _a.filter((child) => child.enabled)) || []).length > 1;
    }
    const parsedChildren = menuOption.childrenItems && showChildren ? /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, renderMenuChildren(pathname, menuOption.childrenItems, isMobile, toggleOpen)) : null;
    if (menuOption.parentItem.linkProps) {
      return /* @__PURE__ */ React.createElement(React.Fragment, { key: menuOption.parentItem.text }, /* @__PURE__ */ React.createElement(
        Link,
        {
          to: LinkHelper.normalize(getUrl(menuOption.parentItem.linkProps)),
          key: menuOption.parentItem.text,
          style: { cursor: "pointer" },
          onClick: isMobile ? toggleOpen : void 0,
          "data-testid": menuOption.parentItem.dataTestId
        },
        parsedParentItem
      ), parsedChildren);
    }
    return /* @__PURE__ */ React.createElement(React.Fragment, { key: menuOption.parentItem.text }, parsedParentItem, parsedChildren);
  };
};
const getPrimaryButtonProps = (pathname, buttonPropsList) => {
  let button = buttonPropsList.find((buttonProps) => !!matchPath(pathname, { path: buttonProps.paths }));
  if (button && button.enabled) {
    return button.buttonProps;
  }
  let defaultButton = buttonPropsList.find((buttonProps) => buttonProps.default);
  if (defaultButton && defaultButton.enabled) {
    return defaultButton.buttonProps;
  }
  return null;
};
const ApplicationSidebarBase = ({
  isOpen,
  toggleOpen,
  primaryButtonList,
  menuOptions
}) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const primaryButtonProps = primaryButtonList ? getPrimaryButtonProps(location.pathname, primaryButtonList) : null;
  if (!isOpen) {
    return /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        direction: "column",
        alignItems: "flexStart",
        style: { gap: "16px", paddingTop: "32px", flexWrap: "unset" }
      },
      renderCollapsedMenuItems(location.pathname, menuOptions)
    );
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { minWidth: "210px" } }, primaryButtonProps && /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      alignItems: "center",
      justifyContent: "spaceBetween",
      gutterSize: "s",
      style: { minHeight: "32px" }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true, onClick: isMobile ? toggleOpen : void 0, style: { maxWidth: "206px" } }, primaryButtonProps.onClick && /* @__PURE__ */ React.createElement(
      PmFilledButton,
      {
        text: primaryButtonProps.text,
        onClick: primaryButtonProps.onClick,
        isLoading: false,
        fontSize: "14px",
        fullWidth: true,
        padding: "6px 12px",
        "data-testid": "manager-hub-sidebar-primary-cta-button"
      }
    ), primaryButtonProps.href && /* @__PURE__ */ React.createElement(
      PmFilledButton,
      {
        text: primaryButtonProps.text,
        href: primaryButtonProps.href,
        internalLink: true,
        isLoading: false,
        fontSize: "14px",
        fullWidth: true,
        padding: "6px 12px",
        "data-testid": "manager-hub-sidebar-primary-cta-button"
      }
    ))
  )), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", "data-testid": "management-hub-sidebar", style: { gap: "12px" } }, menuOptions.map(getRenderMenuOption(location.pathname, isMobile, toggleOpen)), !isMobile && /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      responsive: false,
      gutterSize: "s",
      alignItems: "center",
      style: { height: "24px" }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiButtonIcon,
      {
        iconType: URL.getStatic("icons/menu_open-1.svg"),
        iconSize: "original",
        onClick: toggleOpen,
        "aria-label": "Close sidebar menu",
        style: { width: "20px", height: "20px" }
      }
    )),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiText,
      {
        size: "s",
        style: { fontWeight: 600, cursor: "pointer" },
        onClick: toggleOpen,
        color: colors.neutrals.gray800
      },
      "Hide navigation"
    ))
  ))));
};
export { ApplicationSidebarBase };
