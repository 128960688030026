var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import { useGetGlobalBannerVisibility, useSetGlobalBannerVisibility } from "@pm-frontend/shared/store/globalBanner";
import { useGetOpenWorkEntries } from "@pm-frontend/routes/Melds/queries";
import { ManagementWorkLogCheckoutBanner } from "@pm-frontend/apps/management/banners/ManagementWorkLogCheckoutBanner";
import { EuiSpacer } from "@elastic/eui";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
export const BannerEmptySpace = () => {
  const isMobile = useIsMobile();
  const height = isMobile ? "12px" : "28px";
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(EuiSpacer, { style: { height } }));
};
const ManagementGlobalBanners = () => {
  const isBannerVisible = useGetGlobalBannerVisibility();
  const { data: openWorkEntries } = useGetOpenWorkEntries(isBannerVisible);
  const [workLogIdsToIgnore, setWorkLogIdsToIgnore] = useState(() => {
    const savedIds = window.localStorage.getItem("workLogIdsToIgnore");
    return savedIds ? JSON.parse(savedIds) : {};
  });
  const setGlobalManagementBannerVisibility = useSetGlobalBannerVisibility();
  const handleOnCloseWorkLog = (id) => {
    setWorkLogIdsToIgnore((state) => {
      const newState = __spreadProps(__spreadValues({}, state), { [id]: true });
      window.localStorage.setItem("workLogIdsToIgnore", JSON.stringify(newState));
      const nonIgnoredWorkEntriesLength = openWorkEntries == null ? void 0 : openWorkEntries.filter((entry) => !newState[entry.id]).length;
      if (nonIgnoredWorkEntriesLength === 0) {
        setGlobalManagementBannerVisibility(false);
      }
      return newState;
    });
  };
  if (!isBannerVisible) {
    return null;
  }
  if (openWorkEntries && openWorkEntries.length > 0) {
    const filteredOpenWorkEntries = openWorkEntries.filter((entry) => !workLogIdsToIgnore[entry.id]);
    if (filteredOpenWorkEntries.length > 0) {
      return /* @__PURE__ */ React.createElement(ManagementWorkLogCheckoutBanner, { workEntries: filteredOpenWorkEntries, onCloseWorkLog: handleOnCloseWorkLog });
    }
  }
  return /* @__PURE__ */ React.createElement(BannerEmptySpace, null);
};
export { ManagementGlobalBanners };
