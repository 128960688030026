import { AuthUtils } from "@pm-frontend/shared/utils/auth-utils";
import { ManagementGlobalBanners } from "@pm-frontend/apps/management/subcomponents/ManagementGlobalBanners";
import React from "react";
const GlobalBanners = () => {
  if (AuthUtils.getActiveOrgType() === "m") {
    return /* @__PURE__ */ React.createElement(ManagementGlobalBanners, null);
  }
  return null;
};
export { GlobalBanners };
