import Check from "../svg/check-svg";
import classNames from "classnames";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import I from "immutable";
import meldAPI from "./apis/meld-api";
import Modal from "../modal";
import ModalHeader from "../common/components/modal-header";
import ModelUpdateUtils from "../utils/model-update-utils";
import React from "react";
import Warning from "../svg/warning-svg";
import WarningPanel from "../common/components/warning-panel";
import { ErrorList } from "../common/components/errors";
import MeldUtils from "./utils/meld-utils";
import ButtonContainer from "../../../app/components/containers/ButtonContainer";
import Button, { ButtonTypes } from "../../../app/components/buttons/Button";
import { FinishMeldOpened, track } from "@pm-app/utils/analytics";
class MarkTicketCompleteModal extends React.Component {
  constructor(props) {
    super(props);
    const dateString = MeldUtils.getApptEventStart(this.props.meld);
    this.state = {
      data: I.Map({
        is_complete: true,
        date: dateString ? new Date(dateString) : /* @__PURE__ */ new Date()
      }),
      loading: false,
      cancel: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  render() {
    let isComplete = this.state.data.get("is_complete");
    let yesSelectClasses = classNames({
      selection: true,
      checked: isComplete && !this.state.cancel
    });
    let noSelectClasses = classNames({
      selection: true,
      checked: !isComplete && !this.state.cancel
    });
    let cancelSelectClasses = classNames({
      selection: true,
      checked: this.state.cancel
    });
    return /* @__PURE__ */ React.createElement("div", { className: "tenant-meld-complete" }, /* @__PURE__ */ React.createElement(Modal, { isOpen: true }, /* @__PURE__ */ React.createElement(ModalHeader, null, "Close Meld"), /* @__PURE__ */ React.createElement("div", { className: "modal-body" }, /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement(ErrorList, { errors: this.state.serverErrors }), this.renderWarning(), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "label-container" }, /* @__PURE__ */ React.createElement("label", null, "Pick when the work was completed:")), /* @__PURE__ */ React.createElement(
      DateTimePicker,
      {
        defaultValue: this.state.data.get("date"),
        onChange: ModelUpdateUtils.setWithLabel.call(this, "date")
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "label-container" }, /* @__PURE__ */ React.createElement("label", null, "Were you able to complete the work?")))))), /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns meld-finish-choices" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "meld-finish-choice",
        onClick: () => {
          this.setState({
            cancel: false,
            data: this.state.data.merge({
              is_complete: true
            })
          });
        }
      },
      /* @__PURE__ */ React.createElement("label", { htmlFor: "finish-yes" }, "Yes"),
      /* @__PURE__ */ React.createElement("span", { className: "choice-toggle" }, /* @__PURE__ */ React.createElement(
        "input",
        {
          id: "finish-yes",
          readOnly: true,
          checked: isComplete && !this.state.cancel,
          name: "choice",
          type: "radio"
        }
      ), /* @__PURE__ */ React.createElement("span", { className: yesSelectClasses }, /* @__PURE__ */ React.createElement(Check, null)))
    ), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "meld-finish-choice",
        onClick: () => {
          this.setState({
            cancel: false,
            data: this.state.data.merge({
              is_complete: false
            })
          });
        }
      },
      /* @__PURE__ */ React.createElement("label", { htmlFor: "finish-no" }, "No, I can never complete this meld"),
      /* @__PURE__ */ React.createElement("span", { className: "choice-toggle" }, /* @__PURE__ */ React.createElement(
        "input",
        {
          id: "finish-no",
          checked: !isComplete && !this.state.cancel,
          readOnly: true,
          name: "choice",
          type: "radio"
        }
      ), /* @__PURE__ */ React.createElement("span", { className: noSelectClasses }, /* @__PURE__ */ React.createElement(Check, null)))
    ), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "meld-finish-choice",
        onClick: () => {
          this.setState({
            cancel: true,
            data: this.state.data.merge({
              reason: null
            })
          });
        }
      },
      /* @__PURE__ */ React.createElement("label", { htmlFor: "finish-cancel" }, "Not yet, I need more time"),
      /* @__PURE__ */ React.createElement("span", { className: "choice-toggle" }, /* @__PURE__ */ React.createElement("input", { id: "finish-cancel", checked: this.state.cancel, readOnly: true, name: "choice", type: "radio" }), /* @__PURE__ */ React.createElement("span", { className: cancelSelectClasses }, /* @__PURE__ */ React.createElement(Check, null)))
    ))), this.renderNotes(), /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "footer" }, /* @__PURE__ */ React.createElement(ButtonContainer, null, /* @__PURE__ */ React.createElement(Button, { buttonType: ButtonTypes.Cancel, onClick: this.props.close, disabled: this.state.loading }, "Cancel"), /* @__PURE__ */ React.createElement(Button, { className: "save-meld-btn", buttonType: ButtonTypes.Primary, onClick: this.onSubmit }, this.state.loading ? "Saving..." : "Save"))))))));
  }
  renderWarning() {
    if (this.props.bypass) {
      return /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement(WarningPanel, null, /* @__PURE__ */ React.createElement("span", { className: "warning-panel-svg" }, /* @__PURE__ */ React.createElement(Warning, null)), "This meld may not have been scheduled or performed. Make sure the work is actually done before marking as complete.")));
    }
  }
  renderNotes() {
    if (!this.state.cancel) {
      const reasonLabel = this.state.data.get("is_complete") ? "Completion notes:" : "Reason for not completing:";
      return /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement("div", { className: "label-container" }, /* @__PURE__ */ React.createElement("label", null, reasonLabel)), /* @__PURE__ */ React.createElement(
        "textarea",
        {
          placeholder: "Enter notes here",
          className: "finish-reason",
          onChange: ModelUpdateUtils.onChangeSet.call(this, "reason")
        },
        this.state.data.get("reason")
      )));
    }
  }
  onSubmit() {
    if (this.state.cancel) {
      this.props.close();
    } else {
      this.setState({ loading: true });
      meldAPI.markComplete(this.props.meld.get("id"), this.state.data.toJS()).then(() => {
        this.props.onSaved();
      }).catch((res) => {
        this.setState({
          serverErrors: res.messages,
          loading: false
        });
      });
    }
  }
  componentDidMount() {
    track(FinishMeldOpened(this.props.meld.get("id")));
  }
}
export default MarkTicketCompleteModal;
