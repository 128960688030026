import React from "react";
import { EuiAvatar, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiText, EuiToolTip } from "@elastic/eui";
import { badgeColors, colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
const SettingsMenu = ({ avatarExtraText, userName, email, menuSections }) => {
  const isMobile = useIsMobile();
  return /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "column",
      gutterSize: "l",
      className: "eui-yScroll",
      style: isMobile ? { padding: "12px" } : { maxHeight: "80vh", maxWidth: "300px" }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", alignItems: "flexStart", style: { gap: "12px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "center", style: { gap: "12px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiAvatar,
      {
        className: "pm-header-settings-avatar",
        name: userName,
        color: badgeColors.light.softPurple.bg,
        style: {
          height: "40px",
          width: "40px",
          fontFamily: '"Open Sans", Helvetica, Roboto, Arial, sans-serif'
        }
      }
    )), avatarExtraText && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, style: { minWidth: 0, maxWidth: "216px", overflow: "hidden" } }, /* @__PURE__ */ React.createElement(EuiToolTip, { delay: "long", content: avatarExtraText }, /* @__PURE__ */ React.createElement(
      EuiText,
      {
        color: colors.neutrals.gray800,
        style: {
          fontWeight: 600,
          textOverflow: "ellipsis",
          maxInlineSize: "unset",
          whiteSpace: "nowrap",
          overflow: "hidden"
        },
        grow: false
      },
      avatarExtraText
    ))))), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", responsive: false, style: { gap: "2px" }, alignItems: "flexStart" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray900, size: "m", style: { fontWeight: 700 } }, userName)), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray800, size: "xs" }, email)))))),
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, menuSections && /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", alignItems: "flexStart", style: { flexGrow: 0, gap: "12px" } }, menuSections.map((section, index) => {
      return /* @__PURE__ */ React.createElement(React.Fragment, { key: section.key }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, section.item), index !== menuSections.length - 1 && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiHorizontalRule, { margin: "none" })));
    })))
  );
};
export { SettingsMenu };
