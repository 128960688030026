import { matchPath, useLocation } from "react-router-dom";
import { isRedesignToggleEnabled } from "@pm-frontend/shared/store/redesignToggle";
import { normalizePath } from "@pm-frontend/shared/utils/api-helpers";
import Features from "../common/feature-flags";
import { getOrgType } from "@pm-frontend/shared/utils/api-helpers";
export const ReactRouterDomRoutes = {
  accountSettings: normalizePath("account-settings/"),
  expenditureDetail: normalizePath("melds/expenditures/:expenditureId(\\d+)/summary/"),
  feedback: normalizePath("feedback/"),
  integrationSettings: normalizePath("integrations/"),
  invoiceDetail: normalizePath("melds/payments/:id(\\d+)/summary/"),
  meldCalendar: normalizePath("calendar/melds/"),
  // no longer used, but included here to for backwards compatibility
  meldCalendarTech: normalizePath("calendar/techs/"),
  meldCalendarMeldDetails: normalizePath("calendar/melds/:meldId(\\d+)/"),
  meldDetail: normalizePath("meld/:meldId(\\d+)/summary/"),
  meldDetailActivity: normalizePath("meld/:meldId(\\d+)/activity/"),
  meldDetailChat: normalizePath("meld/:meldId(\\d+)/messages/"),
  meldDetailWorkLog: normalizePath("meld/:meldId(\\d+)/log/"),
  meldDetailContacts: normalizePath("meld/:meldId(\\d+)/contacts/"),
  meldDetailReminders: normalizePath("meld/:meldId(\\d+)/reminders/"),
  meldDetailRemindersAdd: normalizePath("meld/:meldId(\\d+)/add-reminder/"),
  meldDetailRemindersEdit: normalizePath("meld/:meldId(\\d+)/reminders/:reminderId(\\d+)/edit"),
  meldDetailEstimates: normalizePath("meld/:meldId(\\d+)/estimates/"),
  meldList: normalizePath("melds/melding/"),
  nexusRequestsList: normalizePath("nexus/requests/"),
  nexusAPIKeysList: normalizePath("nexus/api-keys/"),
  nexusSettings: normalizePath("nexus/settings/"),
  ownerDetail: normalizePath("properties/owners/:ownerId(\\d+)/"),
  ownerDetailSummary: normalizePath("properties/owners/:ownerId(\\d+)/summary/"),
  ownerDetailEdit: normalizePath("properties/owners/:ownerId(\\d+)/edit/"),
  ownerDetailProperties: normalizePath("properties/owners/:ownerId(\\d+)/properties/"),
  pcpDetailEdit: normalizePath("owner-services/:pcpId(\\d+)/edit"),
  pcpDetail: normalizePath("owner-services/:pcpId(\\d+)/"),
  projectList: normalizePath("projects/"),
  // this shouldn't be needed, but something is funky with the routing
  propertyDetail_extra: normalizePath("properties/addresses/:propertyId(\\d+)/"),
  propertyDetail: normalizePath("properties/addresses/:propertyId(\\d+)/"),
  propertyDetailSummary: normalizePath("properties/addresses/:propertyId(\\d+)/summary/"),
  propertyDetailEdit: normalizePath("properties/addresses/:propertyId(\\d+)/edit/"),
  propertyDetailMelds: normalizePath("properties/addresses/:propertyId(\\d+)/melds/"),
  // left in to keep legacy links operational
  propertyDetailBuildings: normalizePath("properties/addresses/:propertyId(\\d+)/buildings/"),
  // left in to keep legacy links operational
  propertyDetailFloors: normalizePath("properties/addresses/:propertyId(\\d+)/floors/"),
  propertyGroupDetail: normalizePath("properties/groups/:propertyGroupId(\\d+)/addresses/"),
  recurringMeldDetail: normalizePath("melds/recurring/:meldId(\\d+)"),
  reports: normalizePath("reporting/"),
  residentDetailEdit: normalizePath("tenants/:residentId(\\d+)/edit"),
  residentDetailMelds: normalizePath("tenants/:residentId(\\d+)/melds/"),
  residentDetail: normalizePath("tenants/:residentId(\\d+)/"),
  users: normalizePath("users/"),
  unitDetail: normalizePath("properties/:id(\\d+)/"),
  unitDetailEdit: normalizePath("properties/:id(\\d+)/edit/"),
  unitDetailSummary: normalizePath("properties/:id(\\d+)/summary/"),
  unitDetailMelds: normalizePath("properties/:id(\\d+)/melds/"),
  unitDetailInvoices: normalizePath("properties/:id(\\d+)/invoices/"),
  unitDetailResidents: normalizePath("properties/:id(\\d+)/tenants/"),
  unitDetailContacts: normalizePath("properties/:id(\\d+)/contacts/"),
  vendorDetail: normalizePath("vendors/:vendorId(\\d+)/summary"),
  vendorSearchList: normalizePath(`vendors/search/`),
  vendorSearchDetail: normalizePath(`vendors/search/:id(\\d+)/`)
};
const productionRoutes = [
  ReactRouterDomRoutes.expenditureDetail,
  ReactRouterDomRoutes.invoiceDetail,
  ReactRouterDomRoutes.meldDetail,
  ReactRouterDomRoutes.meldDetailActivity,
  ReactRouterDomRoutes.meldDetailChat,
  ReactRouterDomRoutes.meldDetailContacts,
  ReactRouterDomRoutes.meldDetailEstimates,
  ReactRouterDomRoutes.meldDetailReminders,
  ReactRouterDomRoutes.meldDetailRemindersAdd,
  ReactRouterDomRoutes.meldDetailRemindersEdit,
  ReactRouterDomRoutes.meldDetailWorkLog,
  ReactRouterDomRoutes.ownerDetail,
  ReactRouterDomRoutes.ownerDetailEdit,
  ReactRouterDomRoutes.ownerDetailProperties,
  ReactRouterDomRoutes.ownerDetailSummary,
  ReactRouterDomRoutes.pcpDetail,
  ReactRouterDomRoutes.pcpDetailEdit,
  ReactRouterDomRoutes.propertyDetail,
  ReactRouterDomRoutes.propertyDetailBuildings,
  ReactRouterDomRoutes.propertyDetailEdit,
  ReactRouterDomRoutes.propertyDetailFloors,
  ReactRouterDomRoutes.propertyDetailMelds,
  ReactRouterDomRoutes.propertyDetailSummary,
  ReactRouterDomRoutes.propertyDetail_extra,
  ReactRouterDomRoutes.propertyGroupDetail,
  ReactRouterDomRoutes.recurringMeldDetail,
  ReactRouterDomRoutes.residentDetail,
  ReactRouterDomRoutes.residentDetailEdit,
  ReactRouterDomRoutes.residentDetailMelds,
  ReactRouterDomRoutes.unitDetail,
  ReactRouterDomRoutes.unitDetailContacts,
  ReactRouterDomRoutes.unitDetailEdit,
  ReactRouterDomRoutes.unitDetailInvoices,
  ReactRouterDomRoutes.unitDetailMelds,
  ReactRouterDomRoutes.unitDetailResidents,
  ReactRouterDomRoutes.unitDetailSummary,
  ReactRouterDomRoutes.vendorDetail
];
const betaRoutes = [];
const developmentRoutes = [
  ReactRouterDomRoutes.meldCalendar,
  ReactRouterDomRoutes.meldCalendarTech,
  ReactRouterDomRoutes.meldCalendarMeldDetails,
  ReactRouterDomRoutes.meldList,
  ReactRouterDomRoutes.projectList,
  ReactRouterDomRoutes.vendorSearchList,
  ReactRouterDomRoutes.vendorSearchDetail,
  ReactRouterDomRoutes.nexusRequestsList,
  ReactRouterDomRoutes.nexusAPIKeysList,
  ReactRouterDomRoutes.nexusSettings
];
export const useShowRedesignOrOriginalPage = () => {
  const location = useLocation();
  return showRedesignOrOriginalPage({ path: location.pathname });
};
export const showRedesignOrOriginalPage = ({ path }) => {
  if (getOrgType() === "n") {
    return "new";
  }
  if (getOrgType() === "e") {
    return "new";
  }
  if (getOrgType() === "v") {
    return "new";
  }
  const redesignToggleEnabled = isRedesignToggleEnabled();
  if (!redesignToggleEnabled) {
    return "old";
  }
  if (Features.isUIRedesignEnabledProduction()) {
    for (let prodRoute of productionRoutes) {
      if (matchPath(path, {
        path: prodRoute,
        exact: true
      })) {
        return "new";
      }
    }
  } else if (Features.isUIRedesignEnabledBeta()) {
    for (let route of [...productionRoutes, ...betaRoutes]) {
      if (matchPath(path, {
        path: route,
        exact: true
      })) {
        return "new";
      }
    }
  } else if (Features.isUIRedesignEnabledDevelopment()) {
    for (let route of [...productionRoutes, ...betaRoutes, ...developmentRoutes]) {
      if (matchPath(path, {
        path: route,
        exact: true
      })) {
        return "new";
      }
    }
  }
  return "old";
};
export const showRedesignToggle = ({ path }) => {
  const redesignToggleEnabled = isRedesignToggleEnabled();
  if (redesignToggleEnabled) {
    return false;
  }
  if (!Features.isUIRedesignEnabledDevelopmentOrProduction()) {
    return false;
  }
  if (Features.isUIRedesignEnabledProduction()) {
    for (let prodRoute of productionRoutes) {
      if (matchPath(path, {
        path: prodRoute,
        exact: true
      })) {
        return true;
      }
    }
  }
  if (Features.isUIRedesignEnabledBeta()) {
    for (let route of [...productionRoutes, ...betaRoutes]) {
      if (matchPath(path, {
        path: route,
        exact: true
      })) {
        return true;
      }
    }
  }
  if (Features.isUIRedesignEnabledDevelopment()) {
    for (let devRoute of [...developmentRoutes, ...betaRoutes, ...productionRoutes]) {
      if (matchPath(path, {
        path: devRoute,
        exact: true
      })) {
        return true;
      }
    }
  }
  return false;
};
export const showRedesignNavigation = () => {
  if (getOrgType() === "n") {
    return true;
  }
  if (getOrgType() === "e") {
    return true;
  }
  if (getOrgType() === "v") {
    return true;
  }
  return isRedesignToggleEnabled();
};
