import React, { forwardRef, useEffect } from "react";
import classnames from "classnames";
import Div100vh from "react-div-100vh";
import { Close } from "../icons";
export const ModalBody = ({ children }) => /* @__PURE__ */ React.createElement("div", { className: "pm-modal-body" }, children);
ModalBody.displayName = "ModalBody";
export const RefModalBody = forwardRef(({ children }, ref) => /* @__PURE__ */ React.createElement("div", { className: "pm-modal-body", ref }, children));
ModalBody.displayName = "ModalBody";
export const ModalFooter = ({ children }) => /* @__PURE__ */ React.createElement("div", { className: "pm-modal-footer" }, children);
ModalFooter.displayName = "ModalFooter";
export var Size = /* @__PURE__ */ ((Size2) => {
  Size2["Small"] = "small";
  Size2["Medium"] = "medium";
  return Size2;
})(Size || {});
export const Modal = ({
  className,
  hasInnerScroll,
  header,
  size = "small" /* Small */,
  isOpen = false,
  onClose,
  children
}) => {
  useEffect(() => {
    window.document.addEventListener("keyup", handleKeyUp);
    if (hasInnerScroll) {
      window.document.body.classList.toggle("pm-modal-open", isOpen);
    }
    return () => {
      window.document.removeEventListener("keyup", handleKeyUp);
      if (hasInnerScroll) {
        window.document.body.classList.remove("pm-modal-open");
      }
    };
  }, []);
  const handleKeyUp = (e) => {
    if (open && e.key === "Escape") {
      e.stopPropagation();
      close();
    }
  };
  const close = () => {
    if (onClose) {
      onClose();
    }
  };
  if (isOpen) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "pm-modal-overlay" }), /* @__PURE__ */ React.createElement(
      Div100vh,
      {
        className: classnames("pm-modal", `pm-modal-${size}`, className, { "pm-has-inner-scroll": hasInnerScroll }),
        style: { height: hasInnerScroll ? "calc(100rvh - 32px)" : "auto" }
      },
      /* @__PURE__ */ React.createElement("div", { className: "pm-modal-header" }, /* @__PURE__ */ React.createElement("h1", null, header), /* @__PURE__ */ React.createElement("button", { className: "pm-modal-close", onClick: close }, /* @__PURE__ */ React.createElement(Close, null))),
      children
    ));
  }
  return null;
};
Modal.displayName = "Modal";
export default Modal;
