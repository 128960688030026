import React, { useState, useEffect, useRef } from "react";
import { Box, Text, Flex, Spacer, Circle, UnorderedList, ListItem, Button } from "@chakra-ui/react";
import { postSummaryData } from "./comment-services";
import { SmallLoader } from "@pm-assets/js/loaders";
import { isEqual } from "lodash";
import { track, ViewChatSummaryClicked } from "@pm-app/utils/analytics";
const SummaryComponent = ({ comments, meld_id }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [viewClicked, setViewClicked] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rateLimitPassed, setRateLimitPassed] = useState(false);
  const prevCommentsRef = useRef([]);
  const handleClose = () => {
    setIsVisible(false);
  };
  const handleViewSummary = () => {
    track(ViewChatSummaryClicked());
    setViewClicked(true);
  };
  useEffect(() => {
    postSummaryData(comments, meld_id, true).then((response) => {
      if (response.message === "No cache available.") {
        setSummaryData([]);
      } else {
        const regex = new RegExp("(?<=\\.)\\s");
        const parsedSummaryData = response.generated_text.split(regex);
        setSummaryData(parsedSummaryData);
      }
    }).catch(() => {
      setSummaryData([]);
    });
  }, []);
  useEffect(() => {
    if (!viewClicked || isEqual(prevCommentsRef.current, comments)) {
      return;
    }
    prevCommentsRef.current = comments;
    setIsLoading(true);
    postSummaryData(comments, meld_id, false).then((response) => {
      const regex = new RegExp("(?<=\\.)\\s");
      const parsedSummaryData = response.generated_text.split(regex);
      setSummaryData(parsedSummaryData);
      setIsLoading(false);
    }).catch((e) => {
      setError(e.message);
      if (e.message === "Request failed with status code 429") {
        setRateLimitPassed(true);
      }
      setIsLoading(false);
    });
  }, [viewClicked]);
  if (!isVisible) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(
    Box,
    {
      minH: "139px",
      padding: "8px 24px",
      borderRadius: "8px",
      background: "linear-gradient(0deg, #F7F9FA, #F7F9FA), linear-gradient(0deg, #DEE2E5, #DEE2E5)",
      border: "1px solid #DEE2E5",
      boxShadow: "0px 1px 10px rgba(17, 117, 204, 0.02)"
    },
    /* @__PURE__ */ React.createElement(Flex, { mt: -2, align: "center" }, /* @__PURE__ */ React.createElement(Text, { "data-testid": "summary-title", fontWeight: "700", style: { color: "black", fontSize: "16px" } }, "AI Summary of Chat:"), /* @__PURE__ */ React.createElement(Spacer, null), /* @__PURE__ */ React.createElement(
      Box,
      {
        "data-testid": "summary-close",
        onClick: handleClose,
        cursor: "pointer",
        fontWeight: "700",
        align: "left",
        fontSize: "16px",
        style: { color: "black" }
      },
      "X"
    )),
    summaryData.length > 0 || viewClicked ? isLoading ? /* @__PURE__ */ React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80px" }, /* @__PURE__ */ React.createElement(SmallLoader, { "data-testid": "loader" })) : error ? /* @__PURE__ */ React.createElement(Text, { "data-testid": "summary-error", style: { color: "red" }, mt: 4 }, rateLimitPassed ? "Your organization has used the maximum summary attempts for this Meld." : "Error generating summary. Please refresh the page or contact support.") : /* @__PURE__ */ React.createElement(UnorderedList, { mt: -2, paddingLeft: "10px" }, summaryData.map((data, index) => /* @__PURE__ */ React.createElement(ListItem, { "data-testid": `summary-item-${index}`, key: index, align: "left", style: { color: "black" } }, /* @__PURE__ */ React.createElement(Circle, { size: "4px", marginRight: "8px", color: "black" }), data))) : /* @__PURE__ */ React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80px" }, /* @__PURE__ */ React.createElement(Button, { onClick: handleViewSummary }, "Summarize Chat"))
  );
};
export default SummaryComponent;
