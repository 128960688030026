import I from "immutable";
import Document from "../../svg/document-svg";
import Meld from "../../svg/meld-svg";
import Tenant from "../../svg/tenant-svg";
var NotificationType = /* @__PURE__ */ ((NotificationType2) => {
  NotificationType2[NotificationType2["MELD"] = 0] = "MELD";
  NotificationType2[NotificationType2["MELDS_NEED_AVAILABILITY"] = 1] = "MELDS_NEED_AVAILABILITY";
  NotificationType2[NotificationType2["EXPIRING_FILE"] = 2] = "EXPIRING_FILE";
  NotificationType2[NotificationType2["REQUEST_TENANT_INVITE"] = 3] = "REQUEST_TENANT_INVITE";
  return NotificationType2;
})(NotificationType || {});
export let notificationFields = I.Set(["id", "group", "activities"]);
let NotificationRecord = I.Record({
  id: null,
  group: null,
  activities: I.List()
});
class Notification extends NotificationRecord {
  getTime() {
    return this.get("activities").first().get("time");
  }
  getTitle() {
    switch (+this.getType()) {
      case 0 /* MELD */:
        let extra_context = this.getIn(["activities", 0, "extra_context"]);
        if (extra_context.has("address_name")) {
          return `${extra_context.get("meld_name")} | ${extra_context.get("address_name")} ${extra_context.get(
            "unit_name"
          )}`;
        } else {
          return extra_context.get("meld_name");
        }
      case 1 /* MELDS_NEED_AVAILABILITY */:
        return "Melds need availability";
      case 2 /* EXPIRING_FILE */:
        return "Vendor files";
      case 3 /* REQUEST_TENANT_INVITE */:
        return "Tenant invite requests";
    }
  }
  getType() {
    return NotificationType[this.get("group").split(":")[0]];
  }
  getIcon() {
    switch (+this.getType()) {
      case 0 /* MELD */:
      case 1 /* MELDS_NEED_AVAILABILITY */:
        return Meld;
      case 3 /* REQUEST_TENANT_INVITE */:
        return Tenant;
      case 2 /* EXPIRING_FILE */:
        return Document;
    }
  }
  isRead() {
    return this.get("activities").every((activity) => {
      if (activity) {
        return activity.isRead();
      } else {
        return false;
      }
    });
  }
}
export default Notification;
