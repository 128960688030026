import React from "react";
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiHeaderSectionItemButton,
  EuiPopover,
  EuiText
} from "@elastic/eui";
import { css } from "@emotion/react";
import { BorderColor, colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
const settingsSectionButtonCss = css`
  & {
    span.euiButtonEmpty__content {
      padding: 0px;
    }
  }
`;
const HeaderSettingsItem = ({
  children,
  avatarProps,
  isOpen,
  setSettingsIsOpen,
  managementName
}) => {
  const isMobile = useIsMobile();
  const avatarSettingsButton = /* @__PURE__ */ React.createElement(
    EuiAvatar,
    {
      className: "pm-header-avatar",
      name: avatarProps.name,
      color: avatarProps.color,
      style: { height: "28px", width: "28px", fontFamily: '"Open Sans", Helvetica, Roboto, Arial, sans-serif' }
    }
  );
  const settingsAvatarButton = isMobile ? /* @__PURE__ */ React.createElement(
    EuiHeaderSectionItemButton,
    {
      "aria-label": "Settings",
      onClick: () => setSettingsIsOpen((state) => !state),
      css: settingsSectionButtonCss
    },
    avatarSettingsButton
  ) : /* @__PURE__ */ React.createElement(
    EuiHeaderSectionItemButton,
    {
      "aria-label": "Settings",
      style: { padding: "0px" },
      onClick: () => setSettingsIsOpen((state) => !state),
      css: settingsSectionButtonCss
    },
    /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        direction: "row",
        responsive: false,
        alignItems: "center",
        style: {
          gap: "26px",
          padding: "8px 12px",
          border: `1px solid ${BorderColor}`,
          borderRadius: "8px"
        }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
        EuiText,
        {
          size: "xs",
          color: colors.neutrals.gray700,
          style: { fontWeight: 600, fontFamily: '"Open Sans", Helvetica, Roboto, Arial, sans-serif' }
        },
        managementName
      )),
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, avatarSettingsButton)
    )
  );
  if (isMobile) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, settingsAvatarButton, isOpen && /* @__PURE__ */ React.createElement(EuiFlyout, { onClose: () => setSettingsIsOpen(false) }, children));
  }
  return /* @__PURE__ */ React.createElement(
    EuiPopover,
    {
      button: settingsAvatarButton,
      isOpen,
      closePopover: () => setSettingsIsOpen(false),
      hasArrow: false
    },
    children
  );
};
export { HeaderSettingsItem };
